@use 'variables' as *;

@import url('https://fonts.googleapis.com/css?family=Playfair+Display|Source+Sans+Pro:200,400');

/* Generic styles */
html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: 'Source Sans Pro', 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar-thumb {
    background-color: $kpg-code-background-color;
    outline: 1px solid darken($kpg-code-background-color, 5%);
    border-radius: pixel-to-rem(15px);
}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 pixel-to-rem(10px) 0 $kpg-box-shadow-color;
    border-radius: pixel-to-rem(15px);
}

body::-webkit-scrollbar {
    width: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Playfair Display', serif;
}

ul,
figure {
    margin-block-start: 1rem;
    margin-block-end: 1rem;
    margin-inline-start: 1rem;
    margin-inline-end: 1rem;
    padding-inline-start: 0;
}

.main-content {
    padding: pixel-to-rem(32px);

    & a {
        background-color: $kpg-color-red;
        text-decoration: none;
        color: white;
        padding: 0.5rem 1rem;
        margin: 0.5rem;
        border-radius: 0.25rem;
        text-align: center;
        display: inline-block;
        transition: all 0.3s;
        &:hover {
            opacity: 0.6;
        }
    }
}
