@use 'sass:math';

// Variables
$kpg-color-blue: #1c0168;
$kpg-color-red: #d62929;
$kpg-border-color: #e2e2e2;
$kpg-font-color: #666666;
$kpg-background-color: #ccc;
$kpg-code-background-color: #ccc;
$kpg-hover-color: #f5f8ff;
$kpg-figcaption-background-color: rgba(0, 0, 0, 0.7);
$kpg-box-shadow-color: rgba(99, 99, 99, 0.2);
$kpg-light-background-color: rgb(241, 244, 247);
$pinterest-red: #e60023;

// Functions
@function strip-unit($value) {
    @return math.div($value, ($value * 0 + 1));
}
@function pixel-to-rem($value) {
    @return #{math.div(strip-unit($value), 16)}rem;
}
