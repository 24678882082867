@use 'variables' as *;

.main {
    width: 100%;
}

.primary-navigation {
    background-color: $kpg-color-blue;
    color: white;

    & ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }

    & li {
        float: left;
    }

    & li a {
        display: block;
        color: white;
        text-align: center;
        padding: pixel-to-rem(16px);
        text-decoration: none;
    }

    & li a:hover {
        background-color: $kpg-color-red;
    }
}

/* footer */
footer {
    background-color: $kpg-color-blue;
    padding: 0.75rem;
    color: white;
    text-align: center;
    font-size: 0.75rem;

    & .logo {
        position: relative;

        & .tooltip {
            background-color: transparent;
            border: none;
            font-size: inherit;

            & .tooltip-text {
                display: none;
                color: white;

                position: absolute;
                top: 75%;
                left: 50%;
                margin-left: pixel-to-rem(12px);
            }

            &:hover {
                & .tooltip-text {
                    display: block;
                }
            }

            & svg {
                stroke-width: 0;

                & path {
                    fill: $pinterest-red;
                }
            }
        }
    }
}
